<template>
  <div>
    <div v-if="Mode !=='custom'" class="d-flex justify-content-between align-items-center">
      <span class="h6">Modes: </span>
      <!-- <ed-dropdown :selected="selectedMode" :listItems="createModeList" :isSelected="setSelectedMode" styles="margin-bottom: 1rem;min-width: 10rem;" customClass="ed-dropdown__sm"/> -->
      <ed-dropdown
        :selected="selectedMode"
        :listItems="modeList"
        :mapFunction="(mode) => mode.mode"
        :isSelected="setSelectedMode"
        styles="margin-bottom: 1rem;min-width: 10rem;"
        customClass="ed-dropdown__sm"
      />
    </div>
    <p class="wikiDescription__text" v-if="selectedMode === 'Detailed'">
      <span class="h6 frequency__header"> Word Frequency </span>
      <table class="wikiDescription__frequency-box table table-stripped">
        <thead>
          <tr>
          <th>Index</th>
          <th>Word</th>
          <th>Frequency</th>
          </tr>
        </thead>
        <tbody>
          <tr class="wikiDescription__frequency" v-for="(freqs, index) in content.detailed" :key="index">
            <td>{{index + 1}}.</td>
            <td class="wikiDescription__frequency--head">{{ freqs.word }}</td>
            <td class="wikiDescription__frequency--tail">{{ freqs.frequency }}</td>
          </tr>
        </tbody>
      </table>
    </p>
    <p class="wikiDescription__text" v-else-if="selectedMode === 'Sentences'">
      <ul class="wikiDescription__sentences-box">
        <li
          class="wikiDescription__sentences"
          v-for="(sentence, index) in content.sentences"
          :key="index"
          :class="{
            'wikiDescription__sentences--happy': sentence.sentiment > 0,
            'wikiDescription__sentences--sad': sentence.sentiment < 0,
            'wikiDescription__sentences--normal': sentence.sentiment === 0
          }"
          v-html="sentence.text"></li>
      </ul>
    </p>
    <p class="wikiDescription__text marked-up-container" v-else-if="selectedMode === 'Marked Up'" v-html="content.marked">
    </p>
    <p class="wikiDescription__text d-flex flex-wrap" v-else-if="selectedMode === 'Entities'">
      <span class="wikiDescription__entities" v-for="(entity, index) in content.entities" :key="index" :class="entityClass(entity.type)">
        <span class="wikiDescription__entities--type">{{ entity.type }}</span>
        <span class="wikiDescription__entities--entity">{{ entity.entity}}</span>
      </span>
    </p>
    <p class="wikiDescription__text markdown-body" v-else-if="selectedMode === 'Default'" v-html="markedContext(content.content)">
    </p>
    <p class="wikiDescription__text markdown-body" v-else v-html="markedContext(content.content)">
    </p>
  </div>
</template>

<script>
import marked from 'marked';
import edDropdown from './ed-forms/ed-dropdown.vue';

export default {
  name: 'ExploreDescription',

  props: {
    content: {
      type: Object,
    },
    Mode: {
      type: String,
      default: 'Default',
    },
  },

  data() {
    return {
      selectedMode: this.Mode || 'Default',
      modeList: this.$store.state.explore_description_settings.modes,
    };
  },

  components: {
    edDropdown,
  },

  computed: {
    createModeList() {
      const newList = [];
      this.modeList.map((mode) => newList.push(mode.mode));
      return newList;
    },
  },

  methods: {
    entityClass(type) {
      if (type === 'DATE' || type === 'PROPN') {
        return 'entity--date';
      }
      if (type === 'ORDINAL') {
        return 'entity--ordinal';
      }
      if (type === 'CARDINAL') {
        return 'entity--cardinal';
      }
      if (type === 'URL' || type === 'NOUN') {
        return 'entity--url';
      }
      return 'entity--other';
    },

    selectMode(event) {
      this.selectedMode = event.target.value;
    },

    markedContext(content) {
      return marked(content);
    },
    setSelectedMode(tab) {
      this.selectedMode = tab;
    },
    getEmoji(count) {
      if (count > 0) {
        return 'wikiDescription__sentences--happy';
      } if (count < 0) {
        return 'wikiDescription__sentences--sad';
      }
      return 'wikiDescription__sentences--normal';
    },
  },

};
</script>

<style lang="scss" scoped>
</style>
