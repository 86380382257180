<template>
  <div class="p-2 wikiDescription">
  <div class="d-flex justify-content-between align-items-center wikiDescription__heading">
    <h6 class="">{{ exploreEntity }}</h6>
    <TextToSpeech :speech="exploreDescription" :label="label"/>
  </div>
  <TextAnalysis :content="exploreDescription"/>
  </div>
</template>

<script>
import winkNLP from 'wink-nlp';
import model from 'wink-eng-lite-web-model';
import its from 'wink-nlp/src/its';
import as from 'wink-nlp/src/as';
import convertObjToArray from '@/plugins/convertObjToArray';
import convertObjToString from '@/plugins/convertObjToString';
import TextToSpeech from '@/common/components/TextToSpeech.vue';
import TextAnalysis from '@/common/components/TextAnalysis.vue';

export default {

  name: 'ExploreDescription',
  data() {
    return {
      description: '',
      label: 'ExploreDescription',
    };
  },
  components: {
    TextToSpeech,
    TextAnalysis,
  },

  created() {
    this.$highway.singleDispatcher('fetchExploreDescription');
  },

  methods: {

    entityClass(type) {
      if (type === 'DATE') {
        return 'entity--date';
      }
      if (type === 'ORDINAL') {
        return 'entity--ordinal';
      }
      if (type === 'CARDINAL') {
        return 'entity--cardinal';
      }
      if (type === 'URL') {
        return 'entity--url';
      }
      return 'entity--other';
    },

    onSpeech() {
      if (!this.textToSpeech) {
        const splitter = (str) => {
          const strs = [];
          let temp = str;
          while (temp.length > 200) {
            const newStr = temp.substring(0, 200);
            temp = temp.substring(200, temp.length);
            strs.push(newStr);
          }
          strs.push(temp);
          return strs;
        };
        const speech = this.$store.state.text_to_speech;
        const splits = splitter(speech);
        splits.map((sp) => {
          const msg = new SpeechSynthesisUtterance(sp);
          window.speechSynthesis.speak(msg);
          return sp;
        });
      } else {
        window.speechSynthesis.cancel();
      }
    },

    onClick() {
      this.speechIcon = this.speechIcon === 'volume-full' ? 'volume-mute' : 'volume-full';
      this.colorClass = this.colorClass === 'color-cool' ? 'color-dumb' : 'color-cool';
      if (!this.textToSpeech) {
        this.$store.dispatch('setTextToSpeech', this.$store.state.explore_description);
      } else {
        this.$store.dispatch('setTextToSpeech', '');
      }
      this.onSpeech();
      this.textToSpeech = !this.textToSpeech;
    },
  },

  computed: {
    exploreDescription() {
      return this.$store.state.explore_description;
    },

    markedUpMode() {
      const nlp = winkNLP(model);
      const doc = nlp.readDoc(this.$store.state.explore_description);
      const obj = [];
      doc.tokens().each((e) => {
        const temp = {};
        temp.word = e.out();
        temp.type = e.out(its.pos);
        obj.push(temp);
      });
      const str = convertObjToString(obj);
      return str;
    },

    entitiyMode() {
      const nlp = winkNLP(model);
      const doc = nlp.readDoc(this.$store.state.explore_description);
      const obj = [];
      doc.entities().each((e) => {
        const temp = {};
        temp.entity = e.out();
        temp.type = e.out(its.type);
        obj.push(temp);
      });
      return obj;
    },

    selectedMode() {
      return this.$store.state.explore_description_settings.selectedMode || 'Default';
    },

    sentenceMode() {
      const nlp = winkNLP(model);
      const doc = nlp.readDoc(this.$store.state.explore_description);
      const sentences = doc.sentences().out();
      return sentences;
    },

    detailedFrequency() {
      const nlp = winkNLP(model);
      const doc = nlp.readDoc(this.$store.state.explore_description);
      // eslint-disable-next-line
      const frequency = doc.tokens().filter((t) => {
        if (!t.out(its.stopWordFlag) && t.out(its.type) === 'word') {
          return t;
        }
      }).out(its.normal, as.bow);
      const freqs = convertObjToArray(frequency);
      const sorted = freqs.sort((a, b) => b.frequency - a.frequency);
      return sorted;
    },

    exploreEntity() {
      return this.$store.state.current_selected_entity.word;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
