function cleanAndSplitText(text) {
  const maxSegmentLength = process.env.VUE_APP_MAX_SEGEMENT_LENGTH || 200;
  const cleanedSentences = [];

  let sentenceStart = 0;
  for (let i = 0; i < text.length; i++) {
    // Check for a sentence end (full stop) or the end of the text
    if (text[i] === '.' || text[i] === '\n' || i === text.length - 1) {
      // Extract the sentence from sentenceStart to i (inclusive)
      let sentence = text.substring(sentenceStart, i + 1);

      // Remove special symbols and newline characters from the sentence
      sentence = sentence.replace(/[^\w\s]/g, '').replace(/\n/g, '');

      // Check if the sentence is longer than the max length
      if (sentence.length > maxSegmentLength) {
        // Try to find the last space character before the max length to avoid cutting words
        const lastSpaceIndex = sentence.lastIndexOf(' ', maxSegmentLength);
        if (lastSpaceIndex !== -1) {
          cleanedSentences.push(sentence.substring(0, lastSpaceIndex).trim());
          sentence = sentence.substring(lastSpaceIndex);
          sentenceStart = sentenceStart + lastSpaceIndex + 1;
        }
      }

      // If the sentence is still longer than the max length or no space found, split it as is
      while (sentence.length > maxSegmentLength) {
        cleanedSentences.push(`${sentence.substring(0, maxSegmentLength).trim()}`);
        sentence = sentence.substring(maxSegmentLength);
      }

      // Only add the sentence to the array if it's not empty
      if (sentence.trim().length > 0) {
        cleanedSentences.push(sentence.trim());
      }

      // Update the sentenceStart to the next character after the full stop or newline character
      sentenceStart = i + 1;
    }
  }

  return cleanedSentences;
}

export default cleanAndSplitText;
