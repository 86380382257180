<template>
  <div class="box-icon box-icon--effect details-controllers-margin">
    <i
      :class="`d-flex bx bx-${speechIcon} ${colorClass}`"
      @click="onClick"
      type="solid"
    ></i>
  </div>
</template>

<script>
import cleanAndSplitText from '../../utils/cleanAndSplitText';

export default {
  name: 'TextToSpeech',

  props: {
    speech: {
      type: Object,
    },
    Mode: {
      type: String,
      default: 'Default',
    },
  },

  data() {
    return {
      speechIcon: 'volume-mute',
      colorClass: 'color-dumb',
      textToSpeech: false,
      spokenText: this.speech || '',
    };
  },

  mounted() {
    if (this.label === 'help') {
      this.$root.$on('textToSpeechHelp', this.onClick);
    } else if (this.label === 'exploreDescription') {
      this.$root.$on('textToSpeechInformation', this.onClick);
    } else {
      this.$root.$on('textToSpeechDetails', this.onClick);
    }
  },

  beforeDestroy() {
    if (this.label === 'help') {
      this.$root.$off('textToSpeechHelp');
    } else if (this.label === 'exploreDescription') {
      this.$root.$off('textToSpeechInformation');
    } else {
      this.$root.$off('textToSpeechDetails');
    }
  },

  computed: {
    speechText() {
      return this.$store.state.text_to_speech;
    },
  },

  methods: {
    onSpeech() {
      if (!this.textToSpeech) {
        // console.log('xvf', this.speech);
        const splits = cleanAndSplitText(this.speech);
        // console.log('xvf', this.speech, splits);
        // let counter = 0;
        let msg;
        splits.forEach((sp) => {
          msg = new SpeechSynthesisUtterance(sp);

          window.speechSynthesis.speak(msg);
          // counter += 1;
        });
        const checkSpeechPending = setInterval(() => {
          if (!window.speechSynthesis.pending) {
            msg.onend = () => {
              this.speechIcon = this.speechIcon === 'volume-full'
                ? 'volume-mute'
                : 'volume-full';
              this.colorClass = this.colorClass === 'box-icon--active'
                ? 'color-dumb'
                : 'box-icon--active';
            };
            clearInterval(checkSpeechPending);
          }
        }, 3000);
        // console.log('xvf', 'end', counter, splits.length);
      } else {
        window.speechSynthesis.cancel();
      }
    },

    onClick() {
      console.log();
      this.speechIcon = this.speechIcon === 'volume-full' ? 'volume-mute' : 'volume-full';
      this.colorClass = this.colorClass === 'box-icon--active'
        ? 'color-dumb'
        : 'box-icon--active';
      if (!this.textToSpeech) {
        this.$store.dispatch('setTextToSpeech', this.speech);
      } else {
        this.$store.dispatch('setTextToSpeech', '');
      }
      this.onSpeech();
      this.textToSpeech = !this.textToSpeech;
    },
  },
};
</script>

<style lang="scss" scoped></style>
