// eslint-disable-next-line
const convertObjToString = (obj) => {
  let str = '';
  for (let i = 0; i < obj.length; i++) {
    if (
      obj[i].word === ','
      || obj[i].word === '.'
      || obj[i].word === '?'
      || obj[i].word === '('
      || obj[i].word === ')'
    ) {
      str = str.substring(0, str.length - 1);
    }
    if (obj[i].type === 'PROPN') {
      str += `<span class="marked-up"><span class='marked-up__highlights'>${obj[i].word}</span><span class='tags tags--propn'>PROPN</span></span> `;
    } else if (obj[i].type === 'NOUN') {
      str += `<span class="marked-up"><span class='marked-up__highlights'>${obj[i].word}</span><span class='tags tags--noun'>NOUN</span></span> `;
    } else {
      str += `${obj[i].word} `;
    }
  }
  return str;
};

module.exports = convertObjToString;
